import React, { useEffect, useState, useRef , useCallback } from 'react';
import { useMessages } from '@context/messages';
import { useLang } from '@context/lang';
import { useView } from '@context/view';
import { useConfig } from '@context/config';
import axios from '@util/axios_config';
import linkify from 'linkifyjs/element'
import { useMessageDraft } from '@context/messageDraft';
import ScreenReaderOnly from '@components/ScreenReaderOnly';
import ContactDisplay from '@components/ContactDisplay/ContactDisplay';
import LabeledContentRow from '@components/LabeledContentRow/LabeledContentRow';
import FileList from '@components/FileList/FileList';
import './Messages.scss';
import './MessageActions.scss';
import DateFormatter from  '../../../util/date_formatter';
import Loader from '../Loaders/loader';
import { AttachmentProvider } from '../../../context/attachment';
import NoThreads from '@components/Views/NoThreads';
import moment from 'moment';

function Messages({ resetToThreadView }) {
    const { folder, threadTitle, setThreadTitle } = useView();
    const { isMobile, viewManager, allowPrint } = useConfig();
    const { isLoading, messages, archiveHandler, isActionLoading } = useMessages();
    const { dict } = useLang();
    const { token } = useConfig();
    const [threadMessages, setThreadMessages] = useState([])
    const [start ,setStart] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    
    useEffect(() => {
        if (messages) {
            
            setThreadMessages(messages.data.message);
            console.log("trigger on message useeffect",messages.data.message.length);
            if(messages.data.message.length<20){
                setHasMore(false);
                setStart(0);
            }
            else {
                setHasMore(true);
            }
        } else {
          setThreadMessages([]);
        }
    }, [messages])


    const fetchMessages = async (threadId, start , folderId) => {
      const url = 'messages';
      try {
          let config = {
              params: { thread_id: threadId, folder_id: folderId ,start: start, count: 20 }
          };
          if (token) {
              config.headers = { Authorization: `Bearer ${token}` };
          }
          let response = await axios.get(url, config);
          console.log(response.data.message);
          if (Array.isArray(response.data.message)) {
              if (response.data.message.length < 20) {
                  setHasMore(false);
              }
              setThreadMessages(prevMessages => [...prevMessages, ...response.data.message]);
          } else {
              throw new TypeError('Response data is not an array');
          }
      } catch (e) {
          console.error(e);
      } 
      };

    useEffect(() => {
      const threadId = threadMessages[0]?.thread_id;
      const folderId = threadMessages[0]?.folder_id;
      if (threadId) {
          fetchMessages(threadId, start , folderId);
      }
    }, [start]);

    
    const lastMessageElementRef = useCallback(node => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
              console.log("intersection reached");
              setStart(prevStart => prevStart + 20);
          }
      });
      console.log(node);
      if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    // const loadMoreMessages = () => {
    //     if (hasMore && !isLoading) {
    //         setStart(prevStart => prevStart + 20);
    //     }
    // };


    useEffect(() => {
      const lastMessageActionBtn = Array.from(document.getElementsByClassName('oms--message-action')).pop();
      const focusOutListener = () => {
        document.getElementsByClassName("oms--thread")?.[0]?.focus()
      }
      lastMessageActionBtn?.addEventListener('focusout', focusOutListener)

      return () => lastMessageActionBtn?.removeEventListener('focusout', focusOutListener)
    }, [threadMessages])
  
    useEffect(() => {
      if (folder === 'drafts') {
        setThreadTitle('');
      }
    }, [folder]);

    const h2Elem = useRef(null); 
    useEffect(() => {
      if(h2Elem.current) {
        h2Elem.current.focus();      
      }
    });
  
    const moveToFolderLabel = folder === 'inbox' ? dict.archiveButton.label: dict.moveToInbox.label;

    const printHandler = () => {
      // Open a new window
      const printWindow = window.open('', '_blank');

      // Create HTML content
      const htmlContent = `
          <html>
          <head>
              <title>Message Detail</title>
              <style>
                  body { font-family: Arial, sans-serif; padding: 20px; }
                  .message { margin-bottom: 10px; }
                  .label { font-weight: bold; }
              </style>
          </head>
          <body>
              <h1>Message Details</h1>
              ${threadMessages.map(message => 
                `
                  <hr/>
                  <div class='message'><span class='label'>From:</span> ${message.sender.name}</div>  
                  <div class='message'><span class='label'>To:</span> ${message.recipients.recipient.map(r => `${r.name}`)}</div>
                  <div class='message'><span class='label'>Sent:</span> ${moment(message.date_created).format('MMM D, YYYY h:mm a')}</div>
                  <div class='message'><span class='label'>Subject:</span> ${message.subject}</div>
                  <hr/>
                  <div>${message.body}</div>
                `
              ).join('')}
              <hr/>
              <div>&copy; ${moment().format('YYYY')} Optum, All rights reserved</div>
          </body>
          </html>
      `;

      // Write the HTML content to the new window
      printWindow.document.write(htmlContent);
      printWindow.document.close();

      // Trigger the print dialog
      printWindow.print();
    }

    return (
        <div>
          <Loader loading={isLoading}>
            {threadMessages.length > 0 
                ? 
                    <>
                        <header className='oms--top-header' data-testid='oms-msg-body-header'>
                            {((isMobile || viewManager === 'singleView') && folder !== 'drafts') && (
                              <button tabIndex='0' className='oms--top-header-back-button' data-testid='oms-msg-arrow-left' onClick={resetToThreadView}>
                                <span className='sm-icon sm-icon--16 icon--arrow_wtail_left oms--action-buttons'></span>
                              </button>)}
                            <h2 tabIndex='-1' ref={h2Elem}>{threadTitle}</h2>
                            <div className='oms--action-buttons'>
                            {['inbox','archive'].includes(folder) && <>
                              {isActionLoading && <Loader loading label=''/>}
                              <button
                                id="archive-button"
                                type='button'
                                className='oms--move-to-folder'
                                onClick={archiveHandler}
                                data-testid='oms-msg-archive-btn'
                              >
                                 {folder === 'inbox' ? <span className='sm-icon sm-icon--16 icon--archive'></span> : <span className='sm-icon sm-icon--16 icon--email' />}
                                <ScreenReaderOnly message={dict.archiveButton['a11y']} />
                                <span style={{ marginLeft: '5px' }} aria-hidden='true'>
                                  {moveToFolderLabel}
                                </span>
                              </button>
                            </>}
                            {allowPrint &&
                              <button id='print-button' 
                                type='button'
                                className='oms--move-to-folder'
                                onClick={printHandler}
                                data-testid='oms-msg-print-btn'>
                                <span className='sm-icon sm-icon--16 icon--print'></span>
                                <span style={{ marginLeft: '5px' }} aria-hidden='true'>
                                  {`Print`}
                                </span>
                              </button>
                              }
                              </div>
                        </header>
                        <div className='oms--messages-body' data-testid='oms-msg-body'>
                            <section role='list' className='messages-container'>
                            <div className= "messages-list">
                            {threadMessages.map((message, index) => (
                                <Message message={message} key={index} index={index} />
                            ))}
                            <div ref={lastMessageElementRef} style={{ height: '1px' }}></div>
                          </div>
                            </section>
                        </div>
                    </>
                : folder === 'drafts' ? <NoThreads /> : <div className='oms--placeholder-message' data-testid='oms-placeholder-message'>{dict.messagesLabel.label}</div>
            }
            </Loader>
        </div>
        
    )
}

function Message ({message, ...props }) {
    const { allowReply } = useConfig();
    const [hideContent, setHideContent] = useState(props.index > 0 ? true : false);
    const { dict } = useLang();
    const { folder } = useView();
    const {
      body,
      message_id,
      sender,
      recipients,
      date_created,
      attachments,
      mid,
      properties_group,
      attributes
    } = message
    const hasAttachments = typeof attachments !== 'undefined'
    let allow_reply = allowReply && isReplyAllowed(attributes, properties_group) && !(folder === 'drafts');
  
    return (
      <article role='listitem' className='oms--message'>
        <header
          className='oms--header'
		   data-testid='oms-message-header'
        >
          <div className='oms--description'>
            <div data-testid="oms--description-body" onClick={e => setHideContent(!hideContent)}>
              <Recipients recipients={recipients.recipient} />
              <LabeledContentRow label={dict.fromLabel.label}>
                <ContactDisplay {...sender} />
              </LabeledContentRow>
              <LabeledContentRow
                label={dict.dateLabel.label}
              >
                <DateContainer
                  className='oms--date'
                  date={date_created}
                  format='message:header'
                />
              </LabeledContentRow>
             </div>
            {(hasAttachments && !hideContent) && (
              <AttachmentProvider>
              <FileList
                files={attachments.attachment}
                mailboxID={mid}
              />
              </AttachmentProvider>
            )}
            {folder === 'drafts' && <div className='oms--draft-subject'>{`${dict.subjectLabel.label}: ${message.subject}`}</div>}
           </div>
          {allow_reply && (
            <div className='oms--message-action-bar' >
              <Reply
                messageContext={message}
              />
            </div>
          )}
		      {folder === 'drafts' && message.draft &&
                <div className='oms--message-action-bar'>
                    <Edit messageContext={message} />
                    <DeleteDraft messageContext={message} />
                </div>
           }
        </header>
		{!hideContent && <MessageBody key={message_id} body={body} />}
      </article>
    )
}

function Recipients({recipients}) {
    const { dict } = useLang();
    const [toRecipients, setToRecipients] = useState([]);
    const [ccRecipients, setCcRecipients] = useState([]);
    const [bccRecipients, setBccRecipients] = useState([]);

    useEffect(() => {
        setToRecipients(recipients.filter(recipient => recipient.recipient_type === 'to'));
        setCcRecipients(recipients.filter(recipient => recipient.recipient_type === 'cc'));
        setBccRecipients(recipients.filter(recipient => recipient.recipient_type === 'bcc'));
    }, [recipients])

    return (
        <div>
            <LabeledContentRow label={dict.toLabel.label}>
                {toRecipients.map((recipient, i) => (
                    <ContactDisplay key={i} {...recipient} onReply='true' />
                ))}
            </LabeledContentRow>
			{ ccRecipients.length > 0 && <LabeledContentRow label={dict.ccLabel.label}>
                {ccRecipients.map((recipient, i) => (
                    <ContactDisplay key={i} {...recipient} onReply='true' />
                ))}
            </LabeledContentRow>}
            {bccRecipients.length > 0 && <LabeledContentRow label={dict.bccLabel.label}>
                {bccRecipients.map((recipient, i) => (
                    <ContactDisplay key={i} {...recipient} onReply='true' />
                ))}
            </LabeledContentRow>}
        </div>
    )
}



function DateContainer(props) {
    return (
        <time className='oms--date' dateTime=''><DateFormatter date={props.date} formatIdentifier={props.format} /></time>
    )
}



function MessageBody(props) {
  const [blockquote, setBlockquote] = useState({});
  const [blockquoteCount, setBlockquoteCount] = useState(0);
  const [blockquoteCollapsed, setBlockquoteCollapsed] = useState(false);
  const htmlContentRef = useRef();

  useEffect(() => {
    linkify(htmlContentRef.current, {});
    const blockquote = htmlContentRef.current.querySelector('blockquote');
    if (blockquote) {
      const blockquoteCount =
        blockquote.querySelectorAll('blockquote').length + 1 // child blockquotes + paren
      blockquote.hidden = true
      setBlockquote(blockquote);
      setBlockquoteCount(blockquoteCount);
      setBlockquoteCollapsed(true);
    }

    // IE 11 requires a real array, since forEach isn't defined on nodeList
    // (or Array.prototype either without a polyfill)
    const [...links] = htmlContentRef.current.querySelectorAll('a:link')

    links.forEach(element => (element.target = '_blank'))
  }, []);

  function showBlockquote(setVisible = true) {
    if (blockquote) {
      blockquote.hidden = setVisible
      setBlockquoteCollapsed(setVisible);
    } else {
      console.error('no blockquote found')
    }
  }

  function toggleBlockquote() {
    showBlockquote(!blockquoteCollapsed)
  }
  return (
    <div>
      <div
        ref={htmlContentRef}
        tabIndex="0"
        className='oms--message-body'
        data-testid='oms--message-body'
        dangerouslySetInnerHTML={{
          __html: props.body.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }}
      />
      {blockquote && !!blockquoteCount && (
        <button
          onClick={toggleBlockquote}
          className='oms--messages.oms--toggle-blockquote'
          data-testid='blockquote-toggle'
        >
          {blockquoteCollapsed ? `Show ${blockquoteCount} More…` : 'Hide…'}
        </button>
      )}
    </div>
  )
}

function Reply ({messageContext}) {
  const { dict } = useLang();
  const { setManager } = useView();
  const { setMessageContext } = useMessageDraft();

  const replyHandler = () => {
	setMessageContext(messageContext);
	setManager('reply');
  }

  return (
    <span className="reply-button-container">
      <button
        className='oms--message-action'
        onClick={replyHandler}
		data-testid="oms-msg-reply-btn"
      >
        <span className='sm-icon sm-icon--16 icon--email_reply' />
        <ScreenReaderOnly message={dict.replyButton['a11y'] + ' ' + messageContext.subject} />
        <span style={{ marginLeft: '5px' }} aria-hidden='true'>
          {dict.replyButton.label}
        </span>
      </button>
    </span>
  )
}

function Edit({messageContext}) {
    const { dict } = useLang();
    const { setManager } = useView();
    const { setMessageContext } = useMessageDraft();

    const editHandler = () => {
        setMessageContext(messageContext);
        if (messageContext.subject.startsWith('Re: ')) {
            setManager('reply');
        } else {
            setManager('compose');
        }
      }

    return (
        <span className="reply-button-container">
      <button
        className='oms--message-action'
        onClick={editHandler}
		data-testid="oms-msg-edit-btn"
      >
        <span className='sm-icon sm-icon--16 icon--edit' />
        <ScreenReaderOnly message={dict.editButton['a11y'] + ' ' + messageContext.subject} />
        <span style={{ marginLeft: '5px' }} aria-hidden='true'>
          {dict.editButton.label}
        </span>
      </button>
    </span>
    )
}

function DeleteDraft({messageContext}) {
    const { dict } = useLang();
    const { deleteDraft } = useMessageDraft();
    const { getDrafts } = useMessages();

    const deleteHandler = () => {
        deleteDraft(messageContext.message_id, () => {getDrafts();});
    }

    return (
        <span className="reply-button-container">
      <button
        className='oms--message-action'
        onClick={deleteHandler}
		data-testid="oms-msg-delete-btn"
      >
        <span className='sm-icon sm-icon--16 icon--trash_delete' />
        <ScreenReaderOnly message={dict.deleteButton['a11y'] + ' ' + messageContext.subject} />
        <span style={{ marginLeft: '5px' }} aria-hidden='true'>
          {dict.deleteButton.label}
        </span>
      </button>
    </span>
    )
}

function isReplyAllowed (attributes, properties_group) {
  let allow_reply = true
  if (attributes) {
    let parsed_attributes = null
    try {
      parsed_attributes = JSON.parse(attributes)
    } catch (e) {
      console && console.error('Unable to parse message attributes: ' + e)
    }
    if (parsed_attributes) {
      const directives = parsed_attributes.messenger_directives
      if (directives && directives.restrict_reply === true) {
        allow_reply = false
      }
    }
  }
  if (properties_group) {
    properties_group.forEach(group => {
      if (group.properties) {
        const properties = JSON.parse(group.properties)
        if (properties) {
          const directives = properties.messenger_directives
          if (directives && directives.restrict_reply === true) {
            allow_reply = false
          }
        }
      }
    })
  }
  return allow_reply
}

export default Messages;
