import React, { useEffect } from 'react';
import './FieldErrors.scss'
import { useLang } from '@context/lang';
import { useFieldErrors } from '@context/fieldErrors';

function FieldErrors() {
    const { dict } = useLang();
    const { fieldErrors } = useFieldErrors();

    const focusErrorField = (error) => (e) => {
        if (!error.id) return;
        //const keyCode = e?.keyCode || e?.which;
        //if (e !== undefined && keyCode && keyCode !== 13) return;
        if (error.ref) {
            const fieldRef = error.ref;
            fieldRef.scrollIntoView();
            fieldRef.focus();
        }
    }
    
    useEffect(() => {
        if (fieldErrors.length > 0) {
            let firstFieldFocused = false;
            fieldErrors.forEach(fieldErr => {
                if (!firstFieldFocused && fieldErr.ref?.isFocusable()) {
                    focusErrorField(fieldErr)()
                    firstFieldFocused = true;
                }
            });
        }
    }, [fieldErrors])

    return (
        <div className='oms--field-errors-container' role='alert'>
            <div className='oms--field-errors-label'>
                <span className="sm-icon icon--alert_filled" />
                {dict.completeRequiredFields.message}
            </div>
        </div>
    );
}
        
export default FieldErrors;
        