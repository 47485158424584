import React, { useState, useEffect } from 'react';
import { useErrors } from './errors';
import { useLang } from './lang';
import { useView } from './view';
import axios from '@util/axios_config';
import { useAnalytics } from './analytics';
import { useThreads } from './threads';
import { useConfig } from './config';

const MessagesContext = React.createContext();

function MessagesProvider({children}) {
    const errors = useErrors();
    const { token } = useConfig();
    const { loadThreads, setUnreadCount } = useThreads();
    const { dict } = useLang();
    const { folder, thread, setNotificationMessage, setThread } = useView();
    const { viewStart, viewEnd, publish } = useAnalytics();
    const [messages, setMessages] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isActionLoading, setIsActionLoading] = useState(false);
    const UNREAD = 0;
    const READ = 1;

    useEffect(() => {
        if (folder != 'drafts') {
            setMessages(null);
        } else {
            getDrafts();
        }
    }, [folder])

    useEffect(() => {
        if (!thread) {
            setMessages(null);
        }
    }, [thread])

    const fetch = async (threadId) => {
        viewStart('read message');
        setIsLoading(true);
        publish({ resource: 'messages' });
        const url = 'messages';
        try {
            let config = {
                params: { thread_id: threadId , count: 20 }
            };
            if (token) {
                config.headers = { Authorization: `Bearer ${token}` };
            }
            let response = await axios.get(url, config)
            setMessages(response);
            markRead(true, threadId);
        } catch (e) {
            errors.add('messages:failure', dict.errMessageLoading.message, e, 'Message load failure')
        } finally {
            setIsLoading(false);
            viewEnd('read message');
        }
    }
    const getDrafts = async () => {
        setMessages(null);
        setIsLoading(true);
        publish({ resource: 'messages' });
        const url = 'messages';
        try {
            let config = {
                params: { folder_type: 'drafts' }
            };
            if (token) {
                config.headers = { Authorization: `Bearer ${token}` };
            }
            let response = await axios.get(url, config)
            setMessages(response);
        } catch (e) {
            errors.add('messages:failure', dict.errMessageLoading.message, e, 'Message load failure')
        } finally {
            setIsLoading(false);
        }
    }
    const archiveHandler = async () => {
        setIsActionLoading(true);
        try {
          const destinationFolderType = folder.toLowerCase() === 'inbox' ? 'archive': 'inbox';
          let getConfig = {
            params: { mailbox_id: thread.mid }
          }
          if (token) {
            getConfig.headers = { Authorization: `Bearer ${token}` };
          }
          const mailboxFoldersRequest = await axios.get('folders', getConfig);
          const allMailboxFolders = mailboxFoldersRequest.data.folder;
          const systemFolders = allMailboxFolders.filter(({ locked }) => locked === 1);
          const destinationFolder = systemFolders.find(({ name }) => name.toLowerCase() === destinationFolderType);
          if (!destinationFolder) {
            errors.add(
              'archive:messages:failure',
              `Unable to move messages to folder ${destinationFolderType} because that folder could not be found for mailbox ${thread.mid}`
            );
          }
          publish({ resource: 'archive', destinationFolderType });
          let config = {};
          if (token) {
              config.headers = { Authorization: `Bearer ${token}` }
          }
          await axios.post(`threads/${thread.thread_id}/folders/${destinationFolder.folder_id}`, null, config);
          setNotificationMessage(`${dict.successMove.message1} ${destinationFolderType} ${dict.successMove.message2}`);
          setMessages(null);
          setThread(null);
          loadThreads();
        } catch (error) {
          errors.add(
            'archive:messages:failure',
            'Unable to archive messages. Please wait a few minutes and try again.',
            error
          );
        } finally{
            setIsActionLoading(false);
        }
    }
    // mark a thread as being read
    const markRead = async (markThreadRead, threadId) => {
        let config = {
            params: {thread_id: threadId}
        };
        if (token) {
            config.headers = { Authorization: `Bearer ${token}` };
        }
        setUnreadCount(threadId);
        return axios.post(`messages/read/${markThreadRead ? READ : UNREAD}`,
            null, config)
    }
    // the provider
    const provider = {
        messages,
        getDrafts,
        archiveHandler,
        isLoading,
        isActionLoading,
        getMessages: (threadId) => fetch(threadId),
        setMessages
    };

    return <MessagesContext.Provider value={provider}>{children}</MessagesContext.Provider>
}

function useMessages() {
    const context = React.useContext(MessagesContext);
    if (!context) {
        throw new Error('useMessages must be used within a MessagesContext provider');
    }
    return context;
}

export { MessagesProvider, useMessages ,MessagesContext}
