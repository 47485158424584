import React from 'react';
import { useLang } from '@context/lang';
import { useView } from '@context/view';
import Notification from '@components/Notification/Notification';

function Heading() {
    const { dict } = useLang();
    const { manager } = useView();

    return (
        <>
            {manager === 'browse' ? 
            <div className='oms--message-center-browse-heading'>
                <h1 className='oms--widget-heading'>
                    {dict.heading.label}
                </h1>
                <div className='oms--widget-notification'>
                    <Notification />
                </div>
                
            </div>
            :
            <div className='oms--message-center-compose-heading'>
                <h1>{dict.composeAMessageLabel.label}</h1>
                <p>{dict.notUrgentMessage.label}</p>
                <p>{dict.call911Message.label}</p>
            </div>
            }
        </>
    )
}

export default Heading;