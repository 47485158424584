import React, { useState, useEffect } from 'react';
import { useConfig } from './config';
import { dictionaryList } from './languages';

const LangContext = React.createContext({
    language: 'en',
    dictionary: dictionaryList.en
});

function LangProvider({children}) {
    const { language } = useConfig();
    const [lang, setLang] = useState('en');

    useEffect(() => {
        setLang(language);
    }, [language]);

    const provider = {
        lang,
        dict: dictionaryList[lang],
    };

    return <LangContext.Provider value={provider}>{children}</LangContext.Provider>
}

function useLang() {
    const context = React.useContext(LangContext);
    if (!context) {
        throw new Error('useLang can only be used within a LangContext provider');
    }
    return context;
}

export { LangProvider, useLang }