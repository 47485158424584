import React, { useEffect, forwardRef, useState } from 'react';
import { FaBold, FaItalic, FaUnderline, FaListUl, FaListOl, FaIndent, FaOutdent } from 'react-icons/fa'; 
import './OptumEditor.scss';

const OptumEditor = forwardRef(({ id, tabIndex, value, onChangeHandler, onKeyUp , field }, ref) => {
    let maxLength = null
    if (field.maxLength) {
        maxLength = field.maxLength
    }

    const editorTabIndex = tabIndex ? tabIndex : '0';
    const divRef = React.useRef(null);
    const valueRef = React.useRef(value);
    const [isBold, setIsBold] = React.useState(false);
    const [isItalic, setIsItalic] = React.useState(false);
    const [isUnderline, setIsUnderline] = React.useState(false);
    const [dynamicMaxLength, setDynamicMaxLength] = useState(maxLength);
    const [remainingCount, setRemainingCount] = React.useState(dynamicMaxLength ? dynamicMaxLength - value.length : null);
    useEffect(() => {
        // if a value is provided and there is no current value, set the value
        // this is for draft messages that have body text
        if (divRef.current.innerHTML === '' && value !== '') {
            divRef.current.innerHTML = value;
        }
    }, [value])

    useEffect(() => {
        setDynamicMaxLength(maxLength);
        if (divRef.current) {
            let text = divRef.current.innerText || '';  // Get the visible text without HTML tags
            text = text.replace(/\n+/g, '\n');
            setRemainingCount(maxLength ? text.length : null); 
        }
    }, [maxLength, value]);

    const handleChange = (e) => {
        let text = divRef.current.innerText || ''; 
        // Get the full HTML content (used to preserve formatting)
        const html = divRef.current.innerHTML;
        text = text.replace(/\n+/g, '\n'); // Replace multiple newlines with a single newline
    
        if (dynamicMaxLength && text.length > dynamicMaxLength) {
            const trimmedText = text.substring(0, dynamicMaxLength);
            div.innerText = trimmedText;
            setRemainingCount(0);
        } else {
            setRemainingCount(dynamicMaxLength ? text.length : null);
        }
        // Update the parent component with the HTML content to preserve the formatting
        onChangeHandler(html);
    };

    // Prevent new characters from being entered when the limit is reached
    const handleKeyDown = (e) => {
        const text = divRef.current.innerText;
        if (dynamicMaxLength && text.length >= dynamicMaxLength && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
            e.preventDefault();
        }
    };


    const handleKeyUp = (e) => {
        if (onKeyUp) onKeyUp(e);
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        // replace carriage returns with newlines
        // we do this here so the html that is generated will convert the newlines to <br> tags
        const cleanCarriageReturns = text.replace(/\r\n/g, '\n');
        const newText = divRef.current.innerText + cleanCarriageReturns;
        if (!dynamicMaxLength || newText.length <= dynamicMaxLength) {
            document.execCommand('insertText', false, cleanCarriageReturns);
            setRemainingCount(dynamicMaxLength ? dynamicMaxLength - newText.length : null);
        } else {
            const allowedText = cleanCarriageReturns.substring(0, dynamicMaxLength - divRef.current.innerText.length);
            document.execCommand('insertText', false, allowedText);
            setRemainingCount(0);
        }
    }

    const performAction = (command) => {
        divRef.current.focus();
        document.execCommand(command, false, null);
        if (command === 'bold') {
            setIsBold(!isBold);
        }
        if (command === 'italic') {
            setIsItalic(!isItalic);
        }
        if (command === 'underline') {
            setIsUnderline(!isUnderline);
        }
        divRef.current.focus();
    }

    return (
        <div className='oms--optum-editor' ref={ref}>
            <div className='oms--editor-toolbar'>
                <button className={isBold ? 'selected' : ''} onClick={() => performAction('bold')} title='bold' data-testid='boldBtn'><FaBold /></button>
                <button className={isItalic ? 'selected' : ''} onClick={() => performAction('italic')} title='italic' data-testid='italicBtn'><FaItalic /></button>
                <button className={isUnderline ? 'selected' : ''} onClick={() => performAction('underline')} title='underline' data-testid='underlineBtn'><FaUnderline /></button>
                <button onClick={() => performAction('insertOrderedList')} title='ordered list' data-testid='orderedListBtn'><FaListOl /></button>
                <button onClick={() => performAction('insertUnorderedList')} title='unordered list' data-testid='unorderedListBtn'><FaListUl /></button>
                <button onClick={() => performAction('indent')} title='increase indent' data-testid='indentBtn'><FaIndent /></button>
                <button onClick={() => performAction('outdent')} title='decrease indent' data-testid='outdentBtn'><FaOutdent /></button>
                
            </div>
            <div className='oms--editable-div-outer'>
                <div className='oms--editable-div'
                    aria-required='true'
                    data-testid='optum-editor'
                    id={id}
                    tabIndex={editorTabIndex}
                    contentEditable
                    role='textarea'
                    onInput={handleChange}
                    onBlur={handleChange}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    ref={divRef}
                    dangerouslySetInnerHTML={{__html: valueRef.current}}
                ></div>
            </div>
            {dynamicMaxLength && (
            <div>
                 {remainingCount} of {maxLength}
            </div>
            )}
        </div>
    );
})

export default OptumEditor;